import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { useGetTripById } from '../../lib/trip';
import MainLayout from '../../components/Layouts/MainLayout';
import ButtonLoading from '../../components/ButtonLoading';
import Loading from '../../components/Loading/Loading';
import { DEFAULT_LOCALE } from '../../constants/defaults';

export default function TripEditLayout({ children, title, handleMutation, customActionButton }) {
  const { tripId } = useParams();
  const queryClient = useQueryClient();
  const { data: trip, isLoading: isLoadingTrip } = useQuery(
    ['trip', tripId],
    () => useGetTripById(tripId),
    {
      staleTime: Infinity
    }
  );

  const mutation = useMutation(
    (values) => {
      return handleMutation(tripId, values);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['trip']);
      }
    }
  );

  if (isLoadingTrip)
    return (
      <MainLayout title={title}>
        <Loading />
      </MainLayout>
    );

  return (
    <Formik
      initialValues={{
        ...trip,
        whats_included2: trip.whats_included2.map((v) => v._id),
        to_bring2: trip.to_bring2.map((v) => v._id),
        guide: trip.guide
      }}
      validateOnChange={false}
      onSubmit={mutation.mutate}>
      {({ values, setFieldValue }) => (
        <Form>
          <MainLayout
            title={title}
            topTitle={trip?.i18n[DEFAULT_LOCALE].title}
            options={
              customActionButton || (
                <ButtonLoading size="sm" type="submit" isLoading={mutation.isLoading}>
                  Save
                </ButtonLoading>
              )
            }
            back={`/trip/${tripId}/options`}>
            {children(values, setFieldValue, trip)}
          </MainLayout>
        </Form>
      )}
    </Formik>
  );
}
