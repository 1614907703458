import toast from 'react-hot-toast';

import { useTranslateText } from '../lib/ai';
import { LOCALES } from '../constants/defaults';

export function useTranslate({ pageLocale, fromLanguage }) {
  const translate = async (glossary, data) => {
    const resp = await toast.promise(
      useTranslateText({
        template: `
          I am an expert translator. My goal is to provide the best translation possible. I will translate your text from ${LOCALES[fromLanguage]} to ${LOCALES[pageLocale]}.
          In the JSON, I will find words that are between curly braces like {{ location }}, this words are variables and I don't have to be translate them, they must be kept them in the original language.
          I have a glossary with the words and their translations, and I will use it to translate the text.
        `,
        query: `
          Please, translate this JSON to ${LOCALES[pageLocale]}, translate only the values and not the keys. Respond with the same json but translated. And remember, you need to use the glossary for the translation.
        `,
        data: typeof data === 'string' ? JSON.stringify({ text: data }) : JSON.stringify(data),
        glossary
      }),
      {
        loading: 'Translating...',
        success: 'Translation completed!',
        error: 'Error translating'
      }
    );
    const { response: json } = await resp.json();
    let parsedResponse;

    try {
      parsedResponse = JSON.parse(json.replace('```json\n', '').replace('\n```', ''));
    } catch (error) {
      parsedResponse = json;
    }

    return parsedResponse;
  };

  return { translate };
}

export function fastTranslate({ pageLocale, fromLanguage, glossary, data }) {
  const { translate } = useTranslate({ pageLocale, fromLanguage });

  return translate(glossary, data);
}
